import React, { useState } from "react";
import RoleTile from "./RoleTile";

const ListItem = ({ item, onCheckboxChange, onInputNumberChange }) => {
  return (

    item.name !== "Loups Garous" ?
      <div>
        <input
          type="checkbox"
          checked={item.checked}
          onChange={() => onCheckboxChange(item.order)}
        />
        <span>{item.name}</span>
      </div>
      :
      <div>
        <input
          type="checkbox"
          checked={item.checked}
          onChange={() => onCheckboxChange(item.order)}
        />
        <span>{item.name}</span>
        <span><input type="number" value={item.Nb} onChange={(e) => onInputNumberChange(item.order, e.target.value)}></input></span>
      </div>
  )
};

// Composant principal qui rend une liste d'éléments
const ListWithCheckboxes = ({ items, onCheckboxChange, onInputNumberChange }) => {
  return (
    <div style={{ columnCount: 2 }}>
      {items.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          onCheckboxChange={onCheckboxChange}
          onInputNumberChange={onInputNumberChange}
        />
      ))}
    </div>
  );
};

const GameCreation = ({ Partie, roles, NbPlayer, onCheckboxChange, onInputNumberChange, onChangeNumberPlayer }) => {

  const [rolesAJoue, setRolesAJoue] = useState([]);

  function AlerteNbplayer({ NbPlayer }) {


    return null;
  }

  function onCheckboxChange(itemId) {
    const updatedRolesAJoue = [...rolesAJoue, itemId];
    // Mettre à jour l'état avec la nouvelle liste
    setRolesAJoue(updatedRolesAJoue);
  }

  function launchGame() {
    fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie/AddRolesToGame`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rolesAJoue)
    })
      .then((res) =>{
        console.log(res.json())
      
        fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie/NextStep`, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then((res) => {
            
            console.log(res.json());
            // nextcyle
            window.location.reload() //refresh page
          })
          .catch((err) => {
            console.log(err.message);
          })})
      .catch((err) => {
        console.log(err.message);

      }); //fetch etape Jeu
    // fetch role post


  }

  function roletile(role)
  {
    if(role.order == 14)
      return (<>
                <RoleTile id={role.order} name={role.name} imageName={role.imageName} select={role.order == 118 } />
                <RoleTile id={role.order} name={role.name} imageName={role.imageName} select={role.order == 118 }/>
                <RoleTile id={role.order} name={role.name} imageName={role.imageName} select={role.order == 118 }/>
            </>)
    else
        return  (<RoleTile id={role.order} name={role.name} imageName={role.imageName === null ? `default` : role.imageName} select={role.order == 118 } onCheckboxChange={onCheckboxChange}/>)


  } 
  function listRole(roles,type){
    return (
      <div className="wrapper">
        {roles.filter((role) => { return role.type == type })
          .map((role) => (
            roletile(role)
          ))}
      </div>
    )
  }

  return (
    <div className="gameCreation">
      <AlerteNbplayer NbPlayer={NbPlayer} />
      <div>
        Nombre de joueurs : <input type="number" name="nbPlayer" id="nbplayer" value={NbPlayer} onChange={onChangeNumberPlayer} />
      </div>
      <h3>Villageois</h3>
      {listRole(roles, 1)}
      <br />
      <h3>Loups-Garous</h3>

      {listRole(roles, 2)}
      <br />
      <h3>Indépendants</h3>

      {listRole(roles, 3)}
      <br />
      <h3>Villageois Spéciaux</h3>

      {listRole(roles, 4)}
      <div>
        <button onClick={launchGame} >Commencer la Partie</button>
      </div>
    </div>
  )
}

export default GameCreation;