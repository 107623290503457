import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Component/Login";
import WerewolveGame from "./Component/WerewolveGame";
import { DataProvider } from "./data/DataContext";

export class App extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/app" element={<WerewolveGame />} />
          <Route path="/" exact element={<WerewolveGame />} />
        </Routes>
      </Router>
    );
  }
}
