import React from "react";

function input(isNumber,onCheckboxChange,id){
    if(isNumber)
    return <input name="rolecheck" type="number" min="0" max="30" />
    else
    return <input name="rolecheck" type="checkbox" onChange={() => onCheckboxChange(id)}
    />
}

export default function RoleTile({id,image,name,select,onCheckboxChange}){
    return(
        <div className="roleTile">
            <img className="imgroletile" src={`data:image/png;base64,${image}`} />
            <label htmlFor="rolecheck">{name}{input(select,onCheckboxChange,id)}</label>
        </div>
    );
}