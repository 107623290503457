import React, { useState } from 'react';
import { useEffect } from 'react';
//import roles from "../data/role.json"

const TabPlayer = ( {tabRole} ) => {
  const [donnees, setDonnees] = useState(tabRole);


    const trierTableau = () => {
      const nouvellesDonnees = [...donnees].sort((a, b) => {
        // Trie par ID si la colonne2 est non vide, sinon, met les lignes vides à la fin
        if (a.Joueur && b.Joueur) {
          return a.order - b.order;
        } else if (!a.Joueur && b.Joueur) {
          return 1; // Met a à la fin
        } else if (a.Joueur && !b.Joueur) {
          return -1; // Met b à la fin
        } else {
          return 0; // Si les deux colonnes2 sont vides, trie par ID
        }
      });
      setDonnees(nouvellesDonnees);
    };

  const handleEdit = (id, colonne, valeur) => {
    // Mise à jour des données
    const nouvellesDonnees = donnees.map((ligne) => (
      ligne.order === id ? { ...ligne, [colonne]: valeur } : ligne
    ));
    setDonnees(nouvellesDonnees);
  };



  return (
    <div>
      <button onClick={trierTableau}>Trier Tableau</button>
    <table>
      <thead>
        <tr style={{ backgroundColor:"white" } }>
          <th>Role</th>
          <th>Joueur</th>
        </tr>
      </thead>
      <tbody>
        {donnees.map((ligne) => (
          <tr key={ligne.order}>
            <td>
              <input
                type="text"
                value={ligne.name}
                onChange={(e) => handleEdit(ligne.order, 'Role', e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                value={ligne.Joueur}
                onChange={(e) => handleEdit(ligne.order, 'Joueur', e.target.value)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default TabPlayer;
