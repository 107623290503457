import React, { useState, useRef, useEffect } from "react";
import nigthSound from "../sound/night.mp3";
import SpotBear from "../sound/montreur-ours.mp3";
import { DayNightCycle } from "./DayNightCycle";
import WerewolvesButton from "./WerewolvesButton";
import TabPlayer from "./TabPlayer";
import GameCreation from "./GameCreation";

const WerewolveGame = () => {
  const [night, setNight] = useState(false);
  const [werewolvesRound, setWerewolvesRound] = useState(false);
  const [role, setRoles] = useState([]);
  const [partie, SetPartie] = useState(null);
  const nightAudioRef = useRef(new Audio(nigthSound));
  const bearAudioRef = useRef(new Audio(SpotBear));
  const [NbPlayer, setNbPlayer] = useState(0);

  const montreurOurs = () => {
    bearAudioRef.current.currentTime = 0;
    bearAudioRef.current.play();
  };

  const newNight = (newNight) => {
    setNight(newNight);
  };

  const handleNbPlayer = (NbPlayer) => {
    setNbPlayer(NbPlayer);
  };

  const handleWerewolves = () => {
    setWerewolvesRound((prevRound) => !prevRound);
  };

  const ResetGame = () => {
    fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie/ResetGame`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Role`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRoles(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        SetPartie(data);
      })
      .catch((err) => {
        console.log(err.message);
      }); //fetch etape Jeu
  }, []);

  if (partie == null || partie.etape == 0) {
    return (
      <div className="werewolvesGame">
        <GameCreation
          roles={role}
          NbPlayer={NbPlayer}
          onChangeNumberPlayer={handleNbPlayer}
        ></GameCreation>
      </div>
    );
  } else
    return (
      <div className="werewolvesGame">
        <DayNightCycle night={newNight} NightAudio={nightAudioRef.current} />
        <span>
          <button onClick={montreurOurs}>Montreur d'ours</button>
          <WerewolvesButton
            night={night}
            werewolvesRound={werewolvesRound}
            onChangeRound={handleWerewolves}
            NightAudio={nightAudioRef.current}
          />
        </span>
        <TabPlayer tabRole={partie.roles} />
        <button onClick={ResetGame}>Reset Partie</button>
        <div id="panelRole"></div>
      </div>
    );
};

export default WerewolveGame;
